import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { Label } from "../components/ui/label";
import { useDispatch } from "react-redux";
import { fetchItems } from "../redux/slices/pharmacySlice";
import { Separator } from "../components/ui/separator";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";
import {
  X,
  CalendarIcon,
  ChevronRight,
  PlusCircle,
  Trash2,
} from "lucide-react";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { format } from "date-fns";
import MultiSelectInput from "../components/custom/MultiSelectInput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { labCategories, labReportFields } from "../assets/Data";
import { SearchSuggestion } from "../components/custom/registration/CustomSearchSuggestion";
import CreateLabReport from "./CreateLabReport";
import { PDFViewer } from "@react-pdf/renderer";
import DischargeSummaryPDF from "../components/custom/reports/DischargeSummaryPDF";
import { dischargePatient } from "../redux/slices/dischargeSlice";
import { useToast } from "../hooks/use-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

const comorbiditiesList = [
  "Hypertension",
  "Diabetes mellitus",
  "Obesity",
  "COPD",
  "Asthma",
  "Coronary artery disease",
  "Congestive heart failure",
  "Chronic kidney disease",
  "Osteoarthritis",
  "Rheumatoid arthritis",
  "Depression",
  "Anxiety disorders",
  "Hypothyroidism",
  "Hyperlipidemia",
  "GERD",
  "Sleep apnea",
  "Osteoporosis",
  "Chronic liver disease",
  "Anemia",
  "Atrial fibrillation",
].map((name) => ({ name }));
const allLabTests = labCategories.flatMap((category) =>
  category.types.map((type) => ({ name: type }))
);

// Add this new component to display the lab report data as a table
const LabReportTable = ({ report }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Test</TableHead>
          <TableHead>Result</TableHead>
          <TableHead>Unit</TableHead>
          <TableHead>Normal Range</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.entries(report).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell>{value.label}</TableCell>
            <TableCell>{value.value}</TableCell>
            <TableCell>{value.unit}</TableCell>
            <TableCell>{value.normalRange}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default function DischargeSummary() {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patient = useSelector((state) =>
    state.patients.patientlist.find((p) => p._id === patientId)
  );

  const medicines = useSelector((state) => state.pharmacy.items);
  const itemsStatus = useSelector((state) => state.pharmacy.itemsStatus);
  useEffect(() => {
    if (itemsStatus === "idle") {
      dispatch(fetchItems());
    }
  }, [dispatch, itemsStatus]);

  const [formData, setFormData] = useState({
    admissionDate: "",
    dateDischarged: "",
    diagnosis: "",
    clinicalSummary: "",
    treatment: "",
    conditionOnAdmission: "",
    conditionOnDischarge: "",
    investigations: [{ name: "", category: "" }],
    medicineAdvice: [{ name: "", dosage: "0-0-0", duration: "" }],
    notes: "",
    comorbidities: [{ name: "" }],
    comorbidityHandling: "separate",
    selectedTest: "",
    selectedCategory: "",
    vitals: {
      admission: {
        bloodPressure: "",
        heartRate: "",
        temperature: "",
        oxygenSaturation: "",
        respiratoryRate: "",
      },
      discharge: {
        bloodPressure: "",
        heartRate: "",
        temperature: "",
        oxygenSaturation: "",
        respiratoryRate: "",
      },
    },
  });

  const [isLabReportOpen, setIsLabReportOpen] = useState(false); // State to manage modal visibility
  const [selectedInvestigation, setSelectedInvestigation] = useState(null); // State to track selected investigation
  const [isPdfPreviewOpen, setIsPdfPreviewOpen] = useState(false);

  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    gender: "",
    contactNumber: "",
    address: "",
    roomNumber: "",
  });
  useEffect(() => {
    if (patient) {
      setFormData((prevData) => ({
        ...prevData,
        admissionDate: patient.bookingDate
          ? new Date(patient.bookingDate).toISOString().split("T")[0]
          : "",
        dateDischarged: patient.dateDischarged || "",
        diagnosis: patient.diagnosis || "",
        clinicalSummary: patient.clinicalSummary || "",
        treatment: patient.treatment || "",
        conditionOnAdmission: patient.conditionOnAdmission || "",
        conditionOnDischarge: patient.conditionOnDischarge || "",
        vitals: {
          admission: {
            bloodPressure: patient.vitals?.admission?.bloodPressure || "",
            heartRate: patient.vitals?.admission?.heartRate || "",
            temperature: patient.vitals?.admission?.temperature || "",
            oxygenSaturation: patient.vitals?.admission?.oxygenSaturation || "",
            respiratoryRate: patient.vitals?.admission?.respiratoryRate || "",
          },
          discharge: {
            bloodPressure: patient.vitals?.discharge?.bloodPressure || "",
            heartRate: patient.vitals?.discharge?.heartRate || "",
            temperature: patient.vitals?.discharge?.temperature || "",
            oxygenSaturation: patient.vitals?.discharge?.oxygenSaturation || "",
            respiratoryRate: patient.vitals?.discharge?.respiratoryRate || "",
          },
        },
        investigations: patient.labReports || [{ name: "", category: "" }],
        medicineAdvice: patient.medicineAdvice || [
          { name: "", dosage: "0-0-0", duration: "" },
        ],
        notes: patient.notes || "",
        comorbidities: patient.comorbidities?.map((comorbidity) => ({
          name: comorbidity,
        })) || [{ name: "" }],
      }));

      setPatientInfo({
        name: patient.patient.name || "",
        age: patient.patient.age || "",
        gender: patient.patient.gender || "",
        contactNumber: patient.patient.contactNumber || "",
        address: patient.patient.address || "",
        roomNumber: patient.assignedRoom?.roomNumber || "",
      });
    }
  }, [patient]);

  const [selectedReport, setSelectedReport] = useState(null);

  const handlePatientInfoChange = (e) => {
    const { name, value } = e.target;
    setPatientInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (field, date) => {
    setFormData((prev) => ({ ...prev, [field]: date }));
  };

  const handleInvestigationChange = (index, suggestion) => {
    const updatedInvestigations = [...formData.investigations];
    updatedInvestigations[index] = {
      name: suggestion.name,
      category: suggestion.category || "",
    };
    setFormData((prev) => ({ ...prev, investigations: updatedInvestigations }));
  };

  const handleAddInvestigation = () => {
    setFormData((prev) => ({
      ...prev,
      investigations: [...prev.investigations, { name: "", category: "" }],
    }));
  };

  const handleRemoveInvestigation = (index) => {
    setFormData((prev) => ({
      ...prev,
      investigations: prev.investigations.filter((_, i) => i !== index),
    }));
  };

  const handleComorbiditiesChange = (newComorbidities) => {
    setFormData((prev) => ({ ...prev, comorbidities: newComorbidities }));
  };

  const handleRemoveSelected = (name) => {
    setFormData((prev) => ({
      ...prev,
      comorbidities: prev.comorbidities.filter((val) => val.name !== name),
    }));
  };

  const handleComorbidityHandlingChange = (value) => {
    setFormData((prev) => ({ ...prev, comorbidityHandling: value }));
  };

  const { toast } = useToast();
  const dischargeStatus = useSelector((state) => state.discharge.status);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dischargeData = {
      patientId: patientId,
      dateDischarged: formData.dateDischarged,
      conditionOnAdmission: formData.conditionOnAdmission,
      conditionOnDischarge: formData.conditionOnDischarge,
      comorbidities: formData.comorbidities.map((c) => c.name),
      clinicalSummary: formData.clinicalSummary,
      diagnosis: formData.diagnosis,
      treatment: formData.treatment,
      medicineAdvice: formData.medicineAdvice.map((m) => ({
        name: m.name,
        duration: m.duration,
        frequency: m.dosage,
      })),
      labReports: formData.investigations.map((i) => ({
        name: i.name,
        report: i.report,
        date: i.date,
      })),
      vitals: formData.vitals,
      notes: formData.notes,
      status: "Discharged",
    };

    try {
      await dispatch(dischargePatient(dischargeData)).unwrap();
      toast({
        title: "Success",
        description: "Patient discharged successfully",
      });
      navigate("/patients");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to discharge patient. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleTestSelect = (suggestion) => {
    setFormData((prev) => ({
      ...prev,
      selectedTest: suggestion.name,
      selectedCategory: suggestion.category,
    }));
  };

  const handleOpenLabReport = (investigation) => {
    setSelectedInvestigation(investigation);
    setIsLabReportOpen(true);
  };

  const handleCloseLabReport = () => {
    setSelectedInvestigation(null);
    setIsLabReportOpen(false);
  };

  const handleSaveLabReport = (reportData) => {
    const updatedInvestigations = formData.investigations.map((inv) =>
      inv.name === selectedInvestigation.name
        ? {
            ...inv,
            report: reportData.report,
            date: reportData.date,
          }
        : inv
    );

    // If the investigation doesn't exist, add it to the list
    if (!updatedInvestigations.some((inv) => inv.name === reportData.name)) {
      updatedInvestigations.push({
        name: reportData.name,
        report: reportData.report,
        date: reportData.date,
      });
    }

    setFormData((prev) => ({ ...prev, investigations: updatedInvestigations }));
    handleCloseLabReport();
  };

  const handleMedicineAdviceChange = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      medicineAdvice: prev.medicineAdvice.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const handleMedicineAdviceSuggestionSelect = (index, suggestion) => {
    handleMedicineAdviceChange(index, "name", suggestion.name);
  };

  const addMedicineAdvice = () => {
    setFormData((prev) => ({
      ...prev,
      medicineAdvice: [
        ...prev.medicineAdvice,
        { name: "", dosage: "0-0-0", duration: "" },
      ],
    }));
  };

  const removeMedicineAdvice = (index) => {
    setFormData((prev) => ({
      ...prev,
      medicineAdvice: prev.medicineAdvice.filter((_, i) => i !== index),
    }));
  };

  const handleVitalsChange = (type, field, value) => {
    setFormData((prev) => ({
      ...prev,
      vitals: {
        ...prev.vitals,
        [type]: {
          ...prev.vitals[type],
          [field]: value,
        },
      },
    }));
  };

  const handlePreviewPDF = () => {
    setIsPdfPreviewOpen(true);
  };

  const handleClosePdfPreview = () => {
    setIsPdfPreviewOpen(false);
  };

  const getCategoryAndTypeForTest = (testName) => {
    for (const category of labCategories) {
      if (category.types.includes(testName)) {
        const type = testName.toLowerCase().replace(/\s+/g, "-");
        return { category: category.name.toLowerCase(), type };
      }
    }
    return {
      category: "other",
      type: testName.toLowerCase().replace(/\s+/g, "-"),
    };
  };

  const renderVitalsInputs = (type) => (
    <div className="grid grid-cols-2 gap-2 text-sm">
      <div className="flex items-center">
        <span className="w-24">BP:</span>
        <Input
          placeholder="mmHg"
          value={formData.vitals[type].bloodPressure}
          onChange={(e) =>
            handleVitalsChange(type, "bloodPressure", e.target.value)
          }
          className="h-8"
        />
      </div>
      <div className="flex items-center">
        <span className="w-24">HR:</span>
        <Input
          placeholder="bpm"
          type="number"
          value={formData.vitals[type].heartRate}
          onChange={(e) =>
            handleVitalsChange(type, "heartRate", e.target.value)
          }
          className="h-8"
        />
      </div>
      <div className="flex items-center">
        <span className="w-24">Temp:</span>
        <Input
          placeholder="°C"
          type="number"
          value={formData.vitals[type].temperature}
          onChange={(e) =>
            handleVitalsChange(type, "temperature", e.target.value)
          }
          className="h-8"
        />
      </div>
      <div className="flex items-center">
        <span className="w-24">SpO2:</span>
        <Input
          placeholder="%"
          type="number"
          value={formData.vitals[type].oxygenSaturation}
          onChange={(e) =>
            handleVitalsChange(type, "oxygenSaturation", e.target.value)
          }
          className="h-8"
        />
      </div>
      <div className="flex items-center">
        <span className="w-24">RR:</span>
        <Input
          placeholder="breaths/min"
          type="number"
          value={formData.vitals[type].respiratoryRate}
          onChange={(e) =>
            handleVitalsChange(type, "respiratoryRate", e.target.value)
          }
          className="h-8"
        />
      </div>
    </div>
  );

  if (!patient)
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );

  const renderTextArea = (name, label) => (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <Textarea
        id={name}
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        required
        className="mt-1 min-h-[6rem] leading-tight"
      />
    </div>
  );

  return (
    <div className="container mx-auto py-4 px-2 sm:px-4 max-w-5xl">
      <Card className="w-full shadow-lg">
        <CardHeader className="bg-primary text-primary-foreground py-2">
          <CardTitle className="text-xl">Discharge Summary</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <div className="bg-secondary/10 rounded-lg p-3 mb-4">
            <h2 className="text-lg font-semibold mb-2 text-primary">
              Patient Information
            </h2>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div className="flex items-center">
                <Label htmlFor="name" className="w-24 font-bold">
                  Name:
                </Label>
                <Input
                  id="name"
                  name="name"
                  value={patientInfo.name}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="age" className="w-24 font-bold">
                  Age:
                </Label>
                <Input
                  id="age"
                  name="age"
                  value={patientInfo.age}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="gender" className="w-24 font-bold">
                  Gender:
                </Label>
                <Input
                  id="gender"
                  name="gender"
                  value={patientInfo.gender}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="contactNumber" className="w-24 font-bold">
                  Contact:
                </Label>
                <Input
                  id="contactNumber"
                  name="contactNumber"
                  value={patientInfo.contactNumber}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="address" className="w-24 font-bold">
                  Address:
                </Label>
                <Input
                  id="address"
                  name="address"
                  value={patientInfo.address}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="roomNumber" className="w-24 font-bold">
                  Room:
                </Label>
                <Input
                  id="roomNumber"
                  name="roomNumber"
                  value={patientInfo.roomNumber}
                  onChange={handlePatientInfoChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="admissionDate" className="w-24 font-bold">
                  Admit Date:
                </Label>
                <Input
                  id="admissionDate"
                  name="admissionDate"
                  type="date"
                  value={formData.admissionDate}
                  onChange={handleInputChange}
                  className="h-8"
                />
              </div>
              <div className="flex items-center">
                <Label htmlFor="dateDischarged" className="w-24 font-bold">
                  Discharge Date:
                </Label>
                <Input
                  id="dateDischarged"
                  name="dateDischarged"
                  type="date"
                  value={formData.dateDischarged}
                  onChange={handleInputChange}
                  className="h-8"
                />
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 gap-4">
              <div>{renderTextArea("diagnosis", "Diagnosis")}</div>
              <div>{renderTextArea("clinicalSummary", "Clinical Summary")}</div>

              <div>
                <Label htmlFor="comorbidities">Comorbidities</Label>
                <div className="mt-1 space-y-2">
                  <div className="flex flex-wrap gap-1">
                    {formData.comorbidities.map((val, index) => (
                      <Badge
                        key={index}
                        variant="primary"
                        className="flex items-center bg-blue-100 text-blue-800 px-1 py-0.5 text-xs rounded"
                      >
                        {val.name}
                        <X
                          className="ml-1 h-3 w-3 cursor-pointer"
                          onClick={() => handleRemoveSelected(val.name)}
                        />
                      </Badge>
                    ))}
                  </div>
                  <div className="flex gap-2">
                    <MultiSelectInput
                      suggestions={comorbiditiesList}
                      selectedValues={formData.comorbidities}
                      setSelectedValues={handleComorbiditiesChange}
                      placeholder="Select comorbidities"
                    />
                    <Select
                      onValueChange={handleComorbidityHandlingChange}
                      defaultValue={formData.comorbidityHandling}
                    >
                      <SelectTrigger className="w-40">
                        <SelectValue placeholder="Handle" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="separate">Separate</SelectItem>
                        <SelectItem value="clinical_summary">
                          Clinical Summary
                        </SelectItem>
                        <SelectItem value="diagnosis">Diagnosis</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>

              <div>
                <Label htmlFor="admissionVitals">Admission Vitals</Label>
                {renderVitalsInputs("admission")}
              </div>

              <div>
                {renderTextArea(
                  "conditionOnAdmission",
                  "Condition on Admission"
                )}
              </div>

              <div>
                <Label htmlFor="investigations">Investigations</Label>
                <div className="space-y-2 mt-2">
                  {formData.investigations.map((investigation, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-1/2 flex items-center space-x-2">
                        <SearchSuggestion
                          suggestions={allLabTests}
                          placeholder="Select investigation"
                          value={investigation.name}
                          setValue={(value) =>
                            handleInvestigationChange(index, { name: value })
                          }
                          onSuggestionSelect={(suggestion) =>
                            handleInvestigationChange(index, suggestion)
                          }
                        />
                        {investigation.date && (
                          <span className="text-sm text-gray-500">
                            {new Date(investigation.date).toLocaleDateString()}
                          </span>
                        )}
                      </div>
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => handleOpenLabReport(investigation)}
                        aria-label="Open Lab Report"
                      >
                        <ChevronRight className="h-5 w-5" />
                      </Button>
                      <Button
                        type="button"
                        variant="destructive"
                        size="icon"
                        onClick={() => handleRemoveInvestigation(index)}
                        disabled={formData.investigations.length === 1}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                  <Button
                    onClick={handleAddInvestigation}
                    variant="outline"
                    className="mt-2 font-semibold"
                  >
                    <PlusCircle className="h-4 w-4 mr-2" /> Add Investigation
                  </Button>
                </div>
              </div>

              <div>{renderTextArea("treatment", "Treatment")}</div>

              <div>
                <Label htmlFor="dischargeVitals">Discharge Vitals</Label>
                {renderVitalsInputs("discharge")}
              </div>

              <div>
                {renderTextArea(
                  "conditionOnDischarge",
                  "Condition on Discharge"
                )}
              </div>

              <div>
                <Label htmlFor="medicineAdvice">Medicine/Advice</Label>
                <div className="space-y-2 mt-2">
                  {formData.medicineAdvice.map((item, index) => (
                    <div key={index} className="grid grid-cols-4 gap-2 mb-2">
                      <SearchSuggestion
                        suggestions={medicines.map((item) => ({
                          name: item.name,
                        }))}
                        placeholder="Select medicine/advice"
                        value={item.name}
                        setValue={(value) =>
                          handleMedicineAdviceChange(index, "name", value)
                        }
                        onSuggestionSelect={(suggestion) =>
                          handleMedicineAdviceSuggestionSelect(
                            index,
                            suggestion
                          )
                        }
                      />
                      <Input
                        placeholder="Dosage"
                        value={item.dosage}
                        onChange={(e) =>
                          handleMedicineAdviceChange(
                            index,
                            "dosage",
                            e.target.value
                          )
                        }
                        className="font-medium"
                      />
                      <Input
                        placeholder="Duration"
                        value={item.duration}
                        onChange={(e) =>
                          handleMedicineAdviceChange(
                            index,
                            "duration",
                            e.target.value
                          )
                        }
                        className="font-medium"
                      />
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => removeMedicineAdvice(index)}
                        disabled={formData.medicineAdvice.length === 1}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                  <Button
                    onClick={addMedicineAdvice}
                    variant="outline"
                    className="mt-2 font-semibold"
                  >
                    <PlusCircle className="h-4 w-4 mr-2" /> Add Medicine/Advice
                  </Button>
                </div>
              </div>

              <div className="flex justify-end mt-4 space-x-2">
                <Button type="button" onClick={handlePreviewPDF}>
                  Preview Discharge Summary
                </Button>
                <Button type="submit" disabled={dischargeStatus === "loading"}>
                  {dischargeStatus === "loading"
                    ? "Discharging..."
                    : "Submit Discharge Summary"}
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>

      {/* Lab Report Modal */}
      {isLabReportOpen && selectedInvestigation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 p-4 overflow-y-auto max-h-[90vh]">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold">
                Lab Report: {selectedInvestigation.name}
              </h2>
              <Button
                onClick={handleCloseLabReport}
                variant="ghost"
                size="icon"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            {selectedInvestigation.report ? (
              <div>
                <p className="mb-2">Date: {selectedInvestigation.date}</p>
                <LabReportTable report={selectedInvestigation.report} />
                <div className="mt-4 flex justify-end">
                  <Button onClick={handleCloseLabReport}>Close</Button>
                </div>
              </div>
            ) : (
              (() => {
                const { category, type } = getCategoryAndTypeForTest(
                  selectedInvestigation.name
                );
                return (
                  <CreateLabReport
                    category={category}
                    type={type.replace(/[()]/g, "")}
                    patientData={patient}
                    onClose={handleCloseLabReport}
                    onSave={handleSaveLabReport}
                  />
                );
              })()
            )}
          </div>
        </div>
      )}
      {isPdfPreviewOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 h-5/6 p-4">
            <div className="flex justify-between items-center mb-3">
              <h2 className="text-lg font-semibold">
                Discharge Summary Preview
              </h2>
              <Button
                onClick={handleClosePdfPreview}
                variant="ghost"
                size="icon"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            <PDFViewer width="100%" height="90%">
              <DischargeSummaryPDF formData={formData} patient={patientInfo} />
            </PDFViewer>
          </div>
        </div>
      )}
    </div>
  );
}
